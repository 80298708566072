import React, {
  createContext,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  KeyboardEvent,
} from 'react';
import { processUploadedFile } from 'utils/uploadUtils';
import { domain_regex, email_regex, parseCSVContent } from 'utils/util';
import { Option } from 'hooks/Prospects/useIcpFilter';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { capitalize } from 'lodash';
import { uniqBy } from 'utils/uniqBy';
import { jobTitles } from 'components/ProspectList/ICPBuilder/presets';
import isUUID from 'validator/es/lib/isUUID';
import { useDebounceCallback } from 'usehooks-ts';

export type CSVUploadContextType = {
  rows: string[][];
  setUploadedRows: (rows: string[][]) => void;
  columns: string[];
  setUploadedColumns: (columns: string[]) => void;
  selectedColumn: string | null;
  setSelectedColumn: (column: string | null) => void;
  selectedColumnType: string | null;
  setSelectedColumnType: (columnType: string | null) => void;
  errorAlert: string | undefined;
  values: Option[];
  handleAutocompleteChange: (
    e: React.SyntheticEvent<Element, Event>,
    newValues: unknown
  ) => void;
  handleFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileInputDrop: (e: React.DragEvent<HTMLButtonElement>) => void;
  handleReadData: (content: string) => void;
  handleRemoveItem: (item: Option, index: number) => void;
  handleTextInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  file?: File;
  setFile: (file: File) => void;
  fetchedOptions: Option[];
  autocompleteLoading: boolean;
  autocompleteFetching: boolean;
};

// Create the context
const CSVUploadContext = createContext({} as CSVUploadContextType);

export interface CSVUploadProviderProps {
  children: ReactNode;
  dataType: 'profiles' | 'organizations' | 'jobTitles';
  onValuesChanged?: (values: Option[]) => void;
  initialValues?: Option[];
  onFileUploaded?: (file: File) => void;
}

// Create the provider component
export const CSVUploadProvider = ({
  children,
  dataType,
  onValuesChanged,
  initialValues = [],
  onFileUploaded,
}: CSVUploadProviderProps) => {
  // Raw uploaded rows of CSV data.
  const [uploadedRows, setUploadedRows] = useState<string[][]>([]);
  // The columns of the uploaded CSV data.
  const [uploadedColumns, setUploadedColumns] = useState<string[]>([]);
  // Tracks the CSV column that was last selected by the user.
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  // Tracks the type of CSV column (email, domain, etc.)
  const [selectedColumnType, setSelectedColumnType] = useState<string | null>(
    null
  );
  const [autocompleteQuery, setAutocompleteQuery] = useState<string>('');
  const [errorAlert, setErrorAlert] = useState<string | undefined>(undefined);
  const [values, setValues] = useState<Option[]>(initialValues);
  const [manuallyAddedValues, setManuallyAddedValues] =
    useState<Option[]>(initialValues);
  const {
    isLoading: profilesLoading,
    data: profilesData,
    isFetching: profilesFetching,
  } = prospectsApi.useGetProfilesQuery(
    dataType === 'profiles' ? autocompleteQuery : skipToken
  );

  const {
    isLoading: organizationsLoading,
    data: organizationsData,
    isFetching: organizationsFetching,
  } = prospectsApi.useGetOrganizationsQuery(
    dataType === 'organizations' ? autocompleteQuery : skipToken
  );

  const autocompleteLoading =
    dataType === 'profiles'
      ? profilesLoading
      : dataType === 'organizations'
      ? organizationsLoading
      : false;

  const autocompleteFetching =
    dataType === 'profiles'
      ? profilesFetching
      : dataType === 'organizations'
      ? organizationsFetching
      : false;

  const plainTextValuesEnabled = dataType === 'jobTitles';
  const rows = uploadedRows;
  const columns = uploadedColumns;
  const columnIndex = selectedColumn ? columns.indexOf(selectedColumn) : -1;
  const uploadedValues = useMemo(
    () =>
      columnIndex === -1
        ? []
        : uploadedRows
            // Filter out rows for which the selected column is empty.
            .filter((row) => {
              const value = row[columnIndex];
              // Filter out blank values.
              if (!value) {
                return false;
              }

              // Filter out values that don't match the selected column type.
              if (selectedColumnType === 'domain') {
                return domain_regex.test(value);
              }

              if (selectedColumnType === 'email') {
                return email_regex.test(value);
              }

              if (selectedColumnType === 'uuid') {
                return isUUID(value);
              }

              return true;
            })

            // For each row, return a single Option object based on selected column and type (email/domain/uuid).
            .map((row) => {
              // selectedColumn.toLowerCase() === 'domain' ? extractDomainFromUrl(row[columnIndex]
              return {
                label: row[columnIndex],
                value: row[columnIndex].toLowerCase(),
                // e.g., { domain: "xyz.com" }. This is the value that will get sent to the API.
                meta: {
                  [`${selectedColumnType}`]: row[columnIndex].toLowerCase(),
                },
              };
            }),

    [columnIndex, uploadedRows, selectedColumnType]
  );

  // This is the array that will be sent to the backend.

  // Convert any fetched autocomplete options into the Option format.
  const fetchedOptions = useMemo(() => {
    return dataType === 'profiles'
      ? profilesData?.map((p) => {
          const { uuid, name, organization__name } = p;
          return {
            label: `${name} (${organization__name})`,
            value: uuid,
          };
        }) || []
      : dataType === 'organizations'
      ? organizationsData?.map(({ uuid, name }) => ({
          label: `${name}`,
          value: uuid,
        })) || []
      : dataType === 'jobTitles'
      ? jobTitles.map((title) => ({
          label: title,
          value: title,
        }))
      : [];
  }, [dataType, profilesData, organizationsData]);

  // Track the file that was uploaded.
  const [file, setFile] = React.useState<File | undefined>(undefined);

  /**
   * Wrapper for setValues that ensures the values are unique.
   */
  const setValuesUnique = useCallback(
    (f: (prevValues: Option[]) => Option[]) => {
      const augmentedFn = (prevValues: Option[]) => {
        const newValues = f(prevValues);
        return uniqBy(newValues, (a: Option) => {
          return a.value.toLocaleLowerCase();
        });
      };
      setValues(augmentedFn);
    },
    []
  );

  const handleTextInputChange = useDebounceCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAutocompleteQuery(e.target.value);
    },
    200
  );

  const handleRemoveItem = useCallback(
    (item: Option, index: number) => {
      // Filter out the target item from whichever array it's in.
      if (manuallyAddedValues.some((a) => a.value === item.value)) {
        setManuallyAddedValues((prev) =>
          prev.filter((a) => a.value !== item.value)
        );
      } else if (
        uploadedRows.some(
          (a) =>
            a[columnIndex].toLocaleLowerCase() ===
            item.value.toLocaleLowerCase()
        )
      ) {
        setUploadedRows((prev) =>
          prev.filter(
            (a) =>
              a[columnIndex].toLocaleLowerCase() !==
              item.value.toLocaleLowerCase()
          )
        );
      }
    },
    [manuallyAddedValues, uploadedRows, columnIndex, setUploadedRows]
  );

  const handleAutocompleteChange = useCallback(
    (_e: SyntheticEvent<Element, Event>, newValues) => {
      const transformedNewValues = newValues as Option[];

      const newValue = transformedNewValues
        .map((item) => {
          // This is the case when a user types something and hits Enter with freeSolo
          // Check if the last item is a string (freeSolo) and convert to an Option
          if (typeof item === 'string') {
            // Omit this value if plain text entries aren't allowed for this field.
            if (!plainTextValuesEnabled) {
              return undefined;
            }

            return {
              label: capitalize(item),
              value: capitalize(item),
            };
          }
          return {
            label: capitalize(item.label),
            value: capitalize(item.value),
            meta: item.meta,
          };
        })
        .filter((item): item is Option => {
          return item !== undefined;
        })
        .sort();

      setManuallyAddedValues(newValue);
    },
    [plainTextValuesEnabled]
  );

  const handleReadData = useCallback(
    (content: string) => {
      const result = parseCSVContent(content);

      if (result.error) {
        setErrorAlert(result.error);
        return;
      }

      setUploadedRows(result.rows);
      setUploadedColumns(result.columns);
    },
    [setUploadedRows, setUploadedColumns]
  );

  const handleFileInputChange = useCallback(
    async (e) => {
      const file = e.target.files?.[0];

      if (!file) {
        console.error('file upload error: ', e);
        // handle error here
        return;
      }
      setFile(file);

      await processUploadedFile({ file, onComplete: handleReadData });

      // Call onFileUploaded callback if provided
      if (onFileUploaded) {
        onFileUploaded(file);
      }

      // if (event.target) event.target.value = ''; // reset the input value
    },
    [handleReadData, onFileUploaded]
  );

  const handleFileInputDrop = useCallback(
    async (e) => {
      const file = e.dataTransfer?.files[0];

      if (!file) {
        console.error('file upload error: ', e);
        // handle error here
        return;
      }

      setFile(file);

      await processUploadedFile({ file, onComplete: handleReadData });

      // Call onFileUploaded callback if provided
      if (onFileUploaded) {
        onFileUploaded(file);
      }
    },
    [handleReadData, onFileUploaded]
  );

  // Sync new values with the parent component.
  useEffect(() => {
    onValuesChanged?.(values);
    // NOTE: Requiring onValuesChanged here causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setValuesUnique((prevValues) => [
      ...uploadedValues,
      ...manuallyAddedValues,
    ]);
  }, [manuallyAddedValues, uploadedValues, setValuesUnique]);

  return (
    <CSVUploadContext.Provider
      value={{
        rows,
        columns,
        selectedColumn,
        setSelectedColumn,
        selectedColumnType,
        setSelectedColumnType,
        setUploadedRows,
        setUploadedColumns,
        errorAlert,
        values,
        handleAutocompleteChange,
        handleFileInputChange,
        handleFileInputDrop,
        handleReadData,
        handleRemoveItem,
        handleTextInputChange,
        file,
        setFile,
        fetchedOptions,
        autocompleteLoading,
        autocompleteFetching,
      }}
    >
      {children}
    </CSVUploadContext.Provider>
  );
};

// Custom hook to use the CSVUploadContext
export const useCSVUploadContext = () => {
  return useContext(CSVUploadContext);
};
